'use client';

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
interface Props {
  children: React.ReactNode;
}
export default function Provider({
  children
}: Props) {
  return <GoogleReCaptchaProvider reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY}`} scriptProps={{
    async: false,
    // optional, default to false,
    defer: true,
    // optional, default to false
    appendTo: "body",
    // optional, default to "head", can be "head" or "body",
    nonce: undefined
  }} container={{
    // optional to render inside custom element
    element: "",
    // optional, default "div
    parameters: {
      badge: "bottomleft",
      // optional, default undefined
      theme: "dark" // optional, default undefined
    }
  }} data-sentry-element="GoogleReCaptchaProvider" data-sentry-component="Provider" data-sentry-source-file="Provider.tsx">
            {children}
        </GoogleReCaptchaProvider>;
}