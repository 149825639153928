"use client";

import { ReactNode } from "react";
import { NextIntlClientProvider, AbstractIntlMessages } from "next-intl";
type Props = {
  messages: AbstractIntlMessages;
  locale: string;
  children: ReactNode;
  now: Date;
  timeZone: string;
};
export default function NextIntlProvider({
  messages,
  locale,
  children,
  now,
  timeZone
}: Props) {
  return <NextIntlClientProvider locale={locale} messages={messages} defaultTranslationValues={{
    i: text => <i>{text}</i>
  }} now={now} timeZone={timeZone} data-sentry-element="NextIntlClientProvider" data-sentry-component="NextIntlProvider" data-sentry-source-file="NextIntlProvider.tsx">
            {children}
        </NextIntlClientProvider>;
}